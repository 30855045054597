import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export const ContentfulImage = ({ 
    image: {
        localFile: { 
            name, 
            mobile, 
            desktop
        },
        title
    }
}) => {
    const sources = [];
    sources.push( mobile.fluid );
    sources.push( 
        {
            ...desktop.fluid,
            media: `(min-width: 1024px)`,
        }
    );
    return (
        <Img id={ name } fluid={ sources } alt={ title } />
    )
}

export const contentfulImageProperties = graphql`
    fragment ContentfulImageProperties on ContentfulAsset {
        localFile {
            name
            mobile: childImageSharp {
                id
                fluid(maxWidth: 1023, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
            desktop: childImageSharp {
                id
                fluid(maxWidth: 1600, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        description
    }
`

export const LocalImage = ({ images, name, altText }) => {
    const sources = [];
    images.edges.forEach( ( { node }, i ) => {
        if ( ( node.name === name) && ( node.relativeDirectory === 'mobile' ) ) {
            sources.push( node.mobile.fluid );
        }
        if ( ( node.name === name) && ( node.relativeDirectory === 'desktop' ) ) {
            sources.push( 
                {
                    ...node.desktop.fluid,
                    media: `(min-width: 1024px)`,
                }
            );
        }
    })
    return (
        <Img id={ name } fluid={ sources } alt={ altText } />
    )
}

export const localImageProperties = graphql`
    fragment LocalImageProperties on File {
        name
        relativeDirectory
        relativePath
        mobile: childImageSharp {
            id
            fluid(maxWidth: 1023, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        desktop: childImageSharp {
            id
            fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`

export const ShopifyImage = ({ 
    image: {
        localFile: { 
            name, 
            mobile, 
            desktop
        }
    }
}) => {
    const sources = [];
    sources.push( mobile.fluid );
    sources.push( 
        {
            ...desktop.fluid,
            media: `(min-width: 1024px)`,
        }
    );
    return (
        <Img id={ name } fluid={ sources } alt={ name } />
    )
}